import { alpha, Box, Menu, MenuProps, styled as styledMui } from "@mui/material";
import styledCompo from "styled-components";
import {
    Drawer
} from "@mui/material";

export const StyledMenu = styledMui((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }: any) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: "0.6rem",
        minWidth: 180,
        // color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: "0.6rem",
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export const Navbar = styledCompo.div`
.active{
    color:red
}
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 0;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    .outerBox{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .navbarOuterWrapper{
        display: flex;
        justify-content: space-between;
        background-color: red;
        .navbarWrapper{
            .outerBox{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .navbarWrapper{
                    .MuiList-root{
                        .brandsection-links{
                            .navbarRight{
                                .header_menu{
                                    display: flex;
                                    flex-direction: column;
                                    .scroll_link{
                                        cursor: pointer !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            background-color: red;
            .drawerHeader{
                background-color: red;
                .logoSection{
                }
            }
        }
        .drawerBox{
           
        }
        .logoSection{
            padding: 10px !important;
            background-color: red;
        }
    }
    
    .MuiDrawer-paper{
        .drawerHeader{
            display: flex;
            padding-top: 20px;
        }
    }
    .navbarWrapper{
        display: flex;
        align-items: center;
        justify-content:space-between;
        padding: 0 20px 0px 20px;
        @media(max-width: 768px){
            padding: 0 20px 0px 20px;
        }
        @media(max-width: 375px){
            padding: 0 10px 0px 10px;
        }
        .brandsection{
            display: flex;
            align-items: center;
            // width: 213px;
            @media(max-width: 768px){
                // width: 150px;
            }
            @media(max-width: 425px){
                // width: 130px;
            }
            img{
                width: 100%;
                display:flex;
                justify-content:center;
                @media(max-width: 991px){
                    width: 110px;
                }
            }
            
            .brandsection-links {
                display: flex;
                align-items: center;
                margin-left: 120px;
                @media(max-width: 1199px){
                    margin-left: 50px;
                }
                @media(max-width: 1054px){
                    margin-left: 45px;
                }
               
                a {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    text-decoration: none;
                    color: #828380;
                    @media(max-width: 991px){
                        font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    text-decoration: none;
                    color: #828380;
                    }
                    &:hover{
                        color: #E50046;
                    }
                }
                a + a {
                    margin-left: 40px;
                    @media(max-width: 1199px){
                        margin-left: 15px;
                    }
                }
                a.active {
                    color: #E50046;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                } 
                .navLinkFlight.active{
                    color: #82838094;
                    cursor: not-allowed;
                    text-decoration: none;
                },               
            }
        }
        .navbarRight{
            display: flex;
            align-items: center;
            .header_menu{
                margin-right: 26px;
                .scroll_link{
                    cursor:pointer;
                }
                a{
                    color: #010A07;
                    text-decoration: none;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    margin-left: 25px;
                    @media(max-width: 991px){
                        font-size: 15px;
                    }
                }
            }

            .MuiDivider-vertical {
                margin-left: 20px;
            }
            .flag_image{
                width: 50px;
                height: 50px;
                margin-right: 15px;
                object-fit:contain;
            }
            .userImage {
                width: 50px;
                height: 50px;
                margin-right: 15px;
                cursor:pointer;
                object-fit:contain;
            }
            .navbarul{
                display: flex;
                align-items: center;
                padding: 0;
                .selectMenu{
                    padding: 0;
                    em{
                        font-size: 20px;
                        color: #949397;
                        @media(max-width:1280px){
                            font-size: 18px;
                        }
                    }
                    svg{
                        color: #949397;
                    }
                    .MuiInputBase-root:before, .MuiInputBase-root:after{
                        border-bottom:none;
                    }
                }
                .logoutbtnwrap{
                    @media(max-width: 425px){
                        padding: 2px;
                    }
                    .logoutbtn{
                        display: flex;
                        align-items: center;
                        color: #828282;
                        cursor: pointer;
                        img{
                            margin-right: 16px;
                            width: 24px;
                            @media(max-width: 576px){
                                width: 20px;
                                margin-right: 0px;
                            }
                            @media(max-width: 425px){
                                width: 16px;
                            }
                        }
                        p{
                            @media(max-width: 425px){
                                display: none;
                            }
                        }
                    }
                }
            }
            .btnHeader{
                margin-right: 0;
                @media(max-width: 768px){
                    margin-right: 0px;
                }
                .btnSignin{
                    border: 1px solid #ED1C24;
                    border-radius: 25px;
                    color: #010A07;
                    font-weight: 500;
                    font-size: 16px;
                    text-transform: capitalize;
                    padding: 6px 33px;
                    margin-right: 30px;
                    @media(max-width: 1250px){
                        padding: 5px 20px;
                        margin-right: 10px;
                    }
                    @media(max-width: 991px){
                        padding: 4px 12px;
                        font-size: 15px;
                    }
                    @media(max-width: 576px){
                        margin-right: 10px;
                        padding: 6px 30px;
                    }
                    @media(max-width: 424px){
                        margin-right: 10px;
                        padding:3px 15px;
                        font-size: 12px;
                    }
                    &:hover{
                        background:transparent;
                    }
                }
                .btnSignup{
                    background: #ED1C24;
                    border-radius: 25px;
                    color: #fff;
                    font-weight: 500;
                    font-size: 16px;
                    text-transform: capitalize;
                    padding: 7px 33px;
                    @media(max-width: 1250px){
                        padding: 5px 20px;
                    }
                    @media(max-width: 991px){
                        padding: 4px 12px;
                        font-size: 15px;
                    }
                    @media(max-width: 576px){
                        padding: 6px 30px;
                    }
                    @media(max-width: 424px){
                        margin-right: 10px;
                        padding: 3px 15px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
`;

export const CustomDrawer = styledMui(Drawer)({
    "& .logoSection": {
        padding: "15px",
    },
    "& .navLink": {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        textDecoration: 'none',
        color: '#828380',
        "&:hover": {
            color: "#E50046"
        }
    },
    "& .navLinkFlight": {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '19px',
        textDecoration: 'none',
        color: '#82838094',
        cursor: 'not-allowed'
    },
    "& .drawerHeader": {
        "& button": {
            display: 'none'
        }
    },
    "& .outerBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .profileWrapper": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: "15px",
        "& .MuiTypography-root": {
            fontWeight: "500"
        },
        "& .userDrawerImage": {
            width: "50px",
            height: "50px",
            marginRight: "15px",
            cursor: "pointer",
            objectFit: "contain"
        }
    }
});
export const LogoutBtn = styledMui(Box)({
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    "& img": {
        marginRight: "10px"
    }
});

export const DrawerHeader = styledMui(Box)({
    "& .MuiBox-root": {
        display: "flex",
        flexDirection: "column",
        "a": {
            margin: "5%"
        },
        "& .active": {
            color: "red"
        },
        "& .deactive": {
            color: "black"
        },
    },
    "& .btnHeader": {
        display: "flex",
        justifyContent: "center",
        alignItems: "strech",
        "& .btnSignin": {
            border: "1px solid #ED1C24",
            borderRadius: "25px",
            color: "#010A07",
            marginTop: "10px",
            fontWeight: "500",
            fontSize: "16px",
            textTransform: "capitalize",
            padding: "7px 33px",
        },
        "& .btnSignup": {
            marginTop: "10px",
            background: "#ED1C24",
            borderRadius: "25px",
            color: " #fff",
            fontWeight: "500",
            fontSize: "16px",
            textTransform: "capitalize",
            padding: "7px 33px",
        }
    },
});

