import styled from "styled-components";

export const Section = styled("div")`
flex flex-wrap content-center justify-center w-100 h-100 bg-blue`;

export const Article = styled("div")`
  > div {
    margin: 0 auto 16px;
  }
`;

export const Prop = styled("h3")`
f5 f4-ns mb0 white`;

export const Title = styled("h1")`
f4 f3-ns white w-100 tc`;

export const list = [
  {
    prop: "balls",
    name: "Balls",
  },
  {
    prop: "bars",
    name: "Bars",
  },
  {
    prop: "bubbles",
    name: "Bubbles",
  },
  {
    prop: "cubes",
    name: "Cubes",
  },
  {
    prop: "cylon",
    name: "Cylon",
  },
  {
    prop: "spin",
    name: "Spin",
  },
  {
    prop: "spinningBubbles",
    name: "SpinningBubbles",
  },
  {
    prop: "spokes",
    name: "Spokes",
  },
];
