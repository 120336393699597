import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetCountriesAssociations } from "src/Reducers/queries";

type CountryAssoc = {
    forvarderHomeCountry: string
    groupCountriesFirst: string
    groupCountriesSecond: string
    groupCountriesThird: string
}

type ReturnType = [CountryAssoc[], () => void, boolean, () => void, () => void]

export const useCountryAssoc = (): ReturnType => {
    const [update, countiesAssoc] = useLazyQuery(GetCountriesAssociations)
    const [forwarderHomeCountry, setForwarderHomeCountry] = useState<string[]>([]);
    const [groupCountriesFirst, setGroupCountriesFirst] = useState<string[]>([]);
    const [groupCountriesSecond, setGroupCountriesSecond] = useState<string[]>([]);
    const [groupCountriesThird, setGroupCountriesThird] = useState<string[]>([]);
    const [data, setData] = useState<CountryAssoc[]>([])
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        try {
            setLoading(true)
            await update()
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (shouldUpdate) {
            fetchData()
            setShouldUpdate(false)
        }
    }, [shouldUpdate])

    const refetch = () => {
        setShouldUpdate(true)
    }

    const updateData = () => {
        const combined = [] as CountryAssoc[];
        for (let i = 0; i < forwarderHomeCountry.length; i++) {
            combined.push({
                forvarderHomeCountry: forwarderHomeCountry[i] || "",
                groupCountriesFirst: groupCountriesFirst[i] || "",
                groupCountriesSecond: groupCountriesSecond[i] || "",
                groupCountriesThird: groupCountriesThird[i] || ""
            })
        }
        setData(combined);
    }
    
    useEffect(() => {
        if (countiesAssoc.data) {
            setForwarderHomeCountry([...countiesAssoc.data.getCountriesAssociations.map((el: any) => el.countryAssoc)])
            setGroupCountriesFirst([...countiesAssoc.data.getCountriesAssociations.map((el: any) => el.countryAssociated.split(',')[0].trim())])
            setGroupCountriesSecond([...countiesAssoc.data.getCountriesAssociations.map((el: any) => el.countryAssociated.split(',')[1].trim())])
            setGroupCountriesThird([...countiesAssoc.data.getCountriesAssociations.map((el: any) => el.countryAssociated.split(',')[2].trim())])
        }
    }, [countiesAssoc.data])

    useEffect(() => {
        updateData()
    }, [forwarderHomeCountry])

    const addOne = () => {
        setData(prev => [...prev, { forvarderHomeCountry: "", groupCountriesFirst: "", groupCountriesSecond: "", groupCountriesThird: "" }])
    }
    const deleteEmpty = () => {
        setData(prev => prev.filter((el) => el.forvarderHomeCountry))
    }

    return [data, refetch, loading, addOne, deleteEmpty]
}