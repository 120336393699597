import styled from "styled-components";

export const VerifyContent = styled.div`
  position: relative;
  top: 102px;
  @media (max-width: 768px) {
    top: 115px;
  }
  @media (max-width: 425px) {
    top: 115px;
  }
  .footer {
    background-color: #fdae15;
    padding: 9px 28px;
    width: 100%;
    display: flex;
    bottom: 0;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    .copyrights_text {
      color: #051551;
      font-size: 12px;
      margin: 0;
    }
    .right_content {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      line-height: 0;
    }
  }
  .verify_page_wrp {
    .user_verification {
      .content_wrapper {
        .message_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 11px 0 25px 0;
          .outer_loader {
            height: 14px !important;
            width: 100% !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
          }
          .message {
            margin: 0;
            color: #434b9a;
            font-size: 20px;
            font-weight: 800;
            width: 100%;
            text-align: center;
            margin-top:20px;
          }
          .error_message {
            margin: 0;
            color: #ed1c24;
            font-size: 20px;
            font-weight: 800;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {};
