import { errorModalMessage } from 'src/Utils/utility';
import { CustomSnackbar, CustomAlert } from './styled'

const Snackbar = (props) => {
    const {
        alertOpen,
        handleClose,
        severity,
        message,
        vertical = "bottom",
        horizontal = "center",
        autoHideDuration = 10000
    } = props;

    return (
        <CustomSnackbar
            anchorOrigin={{ vertical, horizontal }}
            open={alertOpen}
            autoHideDuration={autoHideDuration}
            onClose={() => {
                if (errorModalMessage === message) {
                    setTimeout(() => handleClose(), 6000)
                } else {
                    handleClose()
                }
            }}
        >
            <CustomAlert onClose={handleClose} severity={severity === 'error' ? 'warning' : severity} sx={{ width: "100%" }}>
                {message}
            </CustomAlert>
        </CustomSnackbar>
    )
}

export default Snackbar