import styledCompo from "styled-components";


export const FooterContainer = styledCompo.div`
.footer_wrp{
    
    .footer_top{
        background-color: #fff;
        padding: 79px 58px;
        .footer_logo{}
        .footer_menu{
            height: 100%;
            .footer_menu_items{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .upper_items{
                    margin-bottom: 13px;
                    display: flex;
                    flex-direction: column;
                    text-decoration:none;
                    input{
                        padding: 11px 16px;
                        border: 1px solid #E0E0E0;
                        width: 100%;
                        height: fit-content;
                    }
                    .emial_fields{
                        display: flex;
                        @media(max-width:767px){
                            .get_started_button{
                                margin-left: -40px;
                                width: 137px;
                                background-color: #ED1C24;
                                color: #fff;
                             border-radius: 25px;
                            height: 44px;
                                        }
                            }
                        }
                    }
                    button{
                        margin-left: -40px;
                        width: 137px;
                        background-color: #ED1C24;
                        color: #fff;
                        border-radius: 25px;
                       }
                       .footer_menu_sub_title{
                            color: #000000;
                            margin-bottom: 27px;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 23px;
                       }

                       .footer_menu_title{
                        font-family: Heebo;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 35px;
                        margin-bottom: 8px;
                        color: #051551;
                       }
                       a{
                        color: #000000;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 30px;
                        text-decoration:none;
                        &:hover{
                            color:#FDAE15;
                        }
                       }
                   }
                   .bottom_items{
                       a{
                        color: #ED1C24;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 23px;
                        text-decoration:none;
                        &:hover {
                            color: #2D347A;
                        }
                       }
                   }
               }
           }
       }
       .footer_bottom{
        background: #FDAE15;
        padding: 30px 56px;
        @media(max-width:767px){
            padding: 15px;
            text-align:center;
        }
        .footer_bottom_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media(max-width:767px){
                flex-wrap: wrap;
                justify-content: center;
            }
            .social_icon{
                display: flex;
                @media(max-width:767px){
                    margin-top: 20px;
                }
                a:first-child {
                    margin-left: 0;
                }
            }
            .menu_items{
                display: flex;
                @media (max-width: 767px) {
                    display: block;
                  }
                h3{
                font-family: Heebo;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: #051551;
                text-decoration:none;
                margin-right: 40px;
                @media (max-width: 767px) {
                    margin-right: 0px;
                    font-size: 14px;
                  }
                }
                a{
                font-family: Heebo;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                color: #051551;
                text-decoration:none;
                margin-right: 40px;
                @media (max-width: 767px) {
                    margin-right: 0px;
                    font-size: 14px;
                  }
                &:hover {
                    color: #2D347A;
                }
                }
            }
        }
        .social_icon{
            a{
                margin-left: 20px;
            }
        }
       }
   }
`;
