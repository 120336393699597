import { Grid, Tab, Tabs, Typography } from "@mui/material"
import { AuthBaseModal } from "../AuthBaseModal"
import { Fragment, useCallback, useMemo, useState } from "react"
import { CheckBoxWithLabel, PasswordInput, SelectOptions, SubmitButton, TermAndPoliscy, TextInput } from "../components/auth"
import { CompanyVerfication } from "../CompanyVerfication"

export type SignUpModalProps = {
    isOpen: boolean
    onClose(): void
    onOpen(): void
}

export const SignUpModal = ({ isOpen, onClose, onOpen }: SignUpModalProps) => {
    const [tabvalue, setTabValue] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [globalEmail, setGlobalEmail] = useState<string>('')
    const [gsaEmail, setGsaEmail] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [isAgree, setIsAgree] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<Map<string, boolean>>(new Map([["email", true], ["password", true], ["firstName", true], ["lastName", true], ["title", true]]));

    const handelChecked = (event: React.ChangeEvent<HTMLInputElement>) => setIsAgree(event.target.checked)
    const calcIsDisabled = () => !(Array.from(isDisabled.values()).filter(value => !value).length === Array.from(isDisabled.values()).length)
    const updateIsDisabled = (key: string) => (value: boolean) => setIsDisabled(prev => prev.set(key, value))

    const titleOptions = useMemo(() => [
        { label: "Mr", value: "Mr" },
        { label: "Mrs", value: "Mrs" },
        { label: "Miss", value: "Miss" },
        { label: "Ms", value: "Ms" },
    ], [])

    const handleOnTab = (_: React.SyntheticEvent, newValue: number) => {
        if (!!newValue) {
            setIsDisabled(prev => {
                prev.delete("email")
                prev.set("globalEmail", true)
                prev.set("gsaEmail", true)
                return new Map(prev)
            })
        } else {
            setIsDisabled(prev => {
                prev.delete("globalEmail")
                prev.delete("gsaEmail")
                prev.set("email", true)
                return new Map(prev)
            })

        }
        setTabValue(!!newValue);
    }

    const textHandler = (state: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLInputElement>) => state(event.target.value)

    const openConfirmModal = () => {
        setIsOpenConfirmModal(true)
        onClose()
    }

    const onBackConfirmModal = useCallback(() => {
        onOpen()
        setIsOpenConfirmModal(false)
    }, [onOpen, setIsOpenConfirmModal])


    const confirmModalProps = useMemo(() => ({
        isOpen: isOpenConfirmModal,
        onClose: () => setIsOpenConfirmModal(false),
        gsaEmail,
        onBack: onBackConfirmModal,
        lastName,
        firstName,
        email,
        globalEmail,
        password,
        title,
        type: tabvalue ? 'gsa' : 'forwarder' as 'gsa' | 'forwarder'
    }), [isOpenConfirmModal, gsaEmail, onBackConfirmModal, lastName, firstName, email, globalEmail, password, title, tabvalue])

    return (
        <Fragment>
            <AuthBaseModal isOpen={isOpen} onClose={onClose} title="Select Account">
                <Fragment>
                    <Tabs
                        value={+tabvalue}
                        onChange={handleOnTab}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Forwarder" />
                        <Tab label="Airline | GSA" />
                    </Tabs>
                    {
                        tabvalue ?
                            <Fragment>
                                <TextInput value={globalEmail} onChange={textHandler(setGlobalEmail)} label="GSA email address" setIsDisabled={updateIsDisabled("globalEmail")} validation="email" />
                                <TextInput value={gsaEmail} onChange={textHandler(setGsaEmail)} label="Global email address" setIsDisabled={updateIsDisabled("gsaEmail")} validation="email" />
                            </Fragment> :
                            <TextInput value={email} onChange={textHandler(setEmail)} label="Email address" setIsDisabled={updateIsDisabled("email")} validation="email" />
                    }
                    <PasswordInput value={password} onChange={textHandler(setPassword)} setIsDisabled={updateIsDisabled("password")} />
                    <Grid container gap={2}>
                        <Grid item xs={2.95}>
                            <SelectOptions title="Title" value={title} options={titleOptions} onChange={event => setTitle(event.target.value)} setIsDisabled={updateIsDisabled("title")} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput value={firstName} onChange={textHandler(setFirstName)} label="First Name" setIsDisabled={updateIsDisabled("firstName")} validation="string" />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput value={lastName} onChange={textHandler(setLastName)} label="Last Name" setIsDisabled={updateIsDisabled("lastName")} validation="string" />
                        </Grid>
                    </Grid>
                    <CheckBoxWithLabel checked={isAgree} onChange={handelChecked}>
                        <Typography>
                            Accept <TermAndPoliscy text="Terms of Service" /> and <TermAndPoliscy text="Privacy Policy" />
                        </Typography>
                    </CheckBoxWithLabel>
                    <SubmitButton onClick={openConfirmModal} text="Agree" isDisabled={!isAgree || calcIsDisabled()} />
                    <Typography align="center">No Credit Card Required</Typography>
                </Fragment>
            </AuthBaseModal>
            <CompanyVerfication {...confirmModalProps} />
        </Fragment>
    )
}