import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
} from "@mui/material";
import Header from "src/Components/Header";
import { LandingContent } from "./styled";
import { renderToString } from "react-dom/server";
import Footer from "src/Components/Footer";
import "react-multi-carousel/lib/styles.css";
import svgMap from 'src/Components/Map/svgmap/dist/svgMap';
import "src/Components/Map/svgmap/dist/svgMap.css";
import { GetGssaCountryList, GetToolTip } from 'src/Reducers/queries'
import { useLazyQuery } from "@apollo/client";
import { GetGssaCountryListQuery } from 'src/Reducers/types/generated/GetGssaCountryListQuery';
import ReactLoading from "react-loading";
import { countryDataType, FormatedCountryDataType, State, tempCountryDataType } from "src/Pages/Landing/types";
import InfoModal from "src/Components/InfoModal";
import { useAuth } from "src/hooks";
import { IntroOverlay } from "src/Components/IntroOverlay";

const Landing = () => {
  const auth = useAuth();
  const sessionTimeout = localStorage.getItem('sessionTimeout')
  const loginAttempt = auth?.userConfig?.loginAttempt;
  const [countryData, setCountryData] = useState<countryDataType>([]);
  const [getGssaCountryList, gssaCountryResponse] = useLazyQuery<GetGssaCountryListQuery>(GetGssaCountryList, {
    fetchPolicy: 'no-cache'
  });
  const [formattedCountryData, setFormattedCountryData] = useState<FormatedCountryDataType>({});
  const [mapLoader, setMapLoader] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getGssaCountryList()
    if (loginAttempt === 1) {
      setOpen(true)
    }
  }, [])
  useEffect(() => {
    if (gssaCountryResponse?.data?.getGssaCountryList.length || [].length > 0) {
      setCountryData(JSON.parse(gssaCountryResponse?.data?.getGssaCountryList[0]?.data || ""))
    }
  }, [gssaCountryResponse?.data?.getGssaCountryList])


  useEffect(() => {
    const getCountryData = () => {
      const mainArray: tempCountryDataType = {};
      countryData.map((country) => {
        Object.keys(country).map((key) => {
          mainArray[key] = {
            "Gssa": renderToString(<> {
              country[key]?.map((item) => {
                return <Box>{item}</Box>
              })
            }</>),
          }
        })
      })
      return mainArray;
    }
    if (Object.keys(getCountryData()).length > 0) {
      setFormattedCountryData(getCountryData());
      setMapLoader(false)
    }
  }, [countryData])

  useEffect(() => {
    if (Object.keys(formattedCountryData).length > 0) {
      new svgMap({
        targetElementID: 'svgMap',
        hideFlag: true,
        colorNoData: "#CCC6C6",
        colorMax: "#F5C003",
        colorMin: "#F5C003",
        data: {
          data: {
            Gssa: {
              name: '',
            },
          },
          applyData: 'Gssa',
          values: formattedCountryData,
        }
      });
    }
  }, [formattedCountryData])

  const [values, setValues] = useState<State>({
    email: "",
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // auth.setUserConfig(null);

  const carouselItem1 = [{
    title: "Forwarder",
    description: "Looking for transparent access to relevant information along your procurement journey? Find available routes, request a quote, make a booking and track your shipments with ALL airlines using a single tool.",
  }, {
    title: "Airline",
    description: "Embrace digital change and maximize your distribution strategy. Do you already have available APIs? All integration efforts happen on CargoAi’s side, promised! Not too sure what APIs are? We have solutions for you as well!",
  }, {
    title: "GSA",
    description: "Representing multiple airlines? Get access to the same services as an airline. Representing multiple airlines? Get access to the same services as an airline.",
  }, {
    title: "Airline",
    description: "Embrace digital change and maximize your distribution strategy. Do you already have available APIs? All integration efforts happen on CargoAi’s side, promised! Not too sure what APIs are? We have solutions for you as well!",
  },
  ];

  const carouselItem2 = [
    'Route',
    'eQuote',
    'eBooking',
    'Rates',
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30
    }
  };
  const [dynamicId, setDynamicId] = useState("")
  useEffect(() => {
    const getScrollLinkId = (number) => {
      switch (number) {
        case 0:
          return "howitworks"
        case 1:
          return "forairlines"
        case 2:
          return "customers"
        default:
          return "";
      }
    }
    setDynamicId(getScrollLinkId(value))
  }, [value])
  const [getToolTip, toolTipData] = useLazyQuery(GetToolTip);
  return (
    <>
      <IntroOverlay open={open} handleClose={handleClose} />
      <Header handleChange={handleChange} setValue={setValue} value={value} getToolTip={getToolTip} toolTipData={toolTipData} />
      <InfoModal
        open={sessionTimeout === null ? false : true}
        header=''
        onClose={() => localStorage.removeItem('sessionTimeout')}
      >
        <Typography sx={{ color: 'rgba(5,21,81,0.9)', fontSize: '18px' }}>{`Your logged-in session has expired. Please login to pick up where you left off`}</Typography>
      </InfoModal>
      <LandingContent>
        <Box className="landing_page_wrp">
          <Box className="location_map" >
            <Box className="lds-wrp">
              <Grid className="banner_wrp" container spacing={2}>
                <Grid item xs={12} md={4} sm={4} lg={4}>
                  <Box className="side_content-wrapper">
                    <Box className="map_side_content">
                      <Typography className="header_content">TRICARGO</Typography>
                      <Typography className="mid_section_content">
                        is a neutral flight
                        search application for
                        cargo.</Typography>
                      <Typography className="main_content">
                        Created by FEDAGSA - Federation of Airline General Sales Agents (the Swiss, neutral, non profit organisation) for our members and others, to offer extensive price and availability comparisons to the market
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sm={8} lg={7}>
                  {mapLoader ?
                    <Box className="lds-inner-wrp"><Box className="lds-ripple"><ReactLoading type={"bars"} color="#3f83f1" />
                    </Box></Box> :
                    <Box id="svgMap" ></Box>
                  }
                </Grid>
                <Box className="map_info"><Box className="map_button"></Box> <Typography>Fedagsa Members</Typography></Box>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Footer />
      </LandingContent >
    </>
  );
};
export default Landing;
