import { createContext } from "react";

interface AuthContext {
  userConfig: any;
  setUserConfig: any;
}

export const authContext = createContext({
  userConfig: null,
  setUserConfig: null,
} as AuthContext);
