import { useState } from "react";

export function useProvideAuth() {
  const userConfigStr = localStorage.getItem("userConfig");
  const defaultUserConfig = userConfigStr ? JSON.parse(userConfigStr) : null;

  const [userConfig, setUserConfig] = useState<any>(defaultUserConfig);
  return {
    userConfig,
    setUserConfig,
  };
}
