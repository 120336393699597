import { Fragment, useState } from "react"
import { AuthBaseModal } from "../AuthBaseModal"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BackButton } from "./styled";
import { AirlinesOnBoard, AirlinesOnBoardInput, CountryInput, NumberInput, PhoneInput, SubmitButton, TextInput } from "../components/auth";
import { Grid, TextField, Typography } from "@mui/material";
import { GetAirLine } from "src/Reducers/queries";
import { useMutation, useQuery } from "@apollo/client";
import { DoRegistration } from "src/Reducers/mutations";
import { CompanyTypes } from "src/Utils/UserPermission";
import { useErrorHandler } from "src/hooks";
import InfoModal from "src/Components/InfoModal";

export type CompanyVerficationProps = {
    isOpen: boolean
    onClose(): void
    onBack(): void
    gsaEmail: string
    lastName: string
    firstName: string
    email: string
    globalEmail: string
    password: string
    title: string
    type: 'gsa' | 'forwarder'
}

export const CompanyVerfication = ({
    isOpen, onClose, onBack, gsaEmail, lastName, firstName, email, globalEmail, password, title, type
}: CompanyVerficationProps) => {
    const [companyName, setCompanyName] = useState<string>('')
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
    const [companyWebsite, setCompanyWebsite] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [IATANumber, setIATANumber] = useState<string>('')
    const [CASSNumber, setCASSNumber] = useState<string>('')
    const [airlinesOnBoard, setAirlinesOnBoard] = useState<any>([])
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isOpenSelectAirline, setIsOpenSelectAirline] = useState<boolean>(false)
    const [fiata, setFiata] = useState<string>('')
    const [isDisabled, setIsDisabled] = useState<Map<string, boolean>>(new Map([["companyName", true], ["country", true], ["IATANumber", true], ["CASSNumber", true], ["phoneNumber", true], ["companyWebsite", true]]));

    const calcIsDisabled = () => !(Array.from(isDisabled.values()).filter(value => !value).length === Array.from(isDisabled.values()).length)
    const updateIsDisabled = (key: string) => (value: boolean) => setIsDisabled(prev => prev.set(key, value))
    const airlines = useQuery(GetAirLine);
    const [signUpcall] = useMutation<any>(DoRegistration);
    const { setMsgConfig } = useErrorHandler();

    const textHandler = (state: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLInputElement>) => state(event.target.value)

    const addAirline = (_: any, value: any) => {
        const valueStrArray: any[] = [];
        value.map((e: any) =>
            valueStrArray.push(e["awbPrefix"])
        );
        setAirlinesOnBoard(value);
    }

    const errorCheck = (err) => {
        if (err.includes("duplicate key")) {
          setMsgConfig({
            alertOpen: true,
            message: "An account with this email already exists! Please use an alternative email address",
            severity: "error",
            autoHideDuration: 5000
          })
        } else {
          setMsgConfig({
            alertOpen: true,
            message: "Sorry, an error has occurred. Please take a screenshot and email it to wayne@tricargo.aero. This will help us to resolve this as soon as possible and we will also respond to assist you. Thank you.!",
            severity: "error",
            autoHideDuration: 5000
          })
        }
    }

    const submit = async () => {
        setIsSubmitting(true)
        try {
            const airlenesSelected = airlinesOnBoard.map((e: any) => e['awbPrefix'])
            const registrar = type === 'gsa' ? CompanyTypes.GSSA : CompanyTypes.FORWARDER
            const currentEmail = registrar === CompanyTypes.GSSA ? gsaEmail : email
            const signUpResponse = await signUpcall({
                variables: {
                    airlinesOnBoard: airlenesSelected.join(),
                    cassNo: CASSNumber,
                    companyname: companyName,
                    companyWebsite: companyWebsite,
                    countrycode: country,
                    emailid: currentEmail.trim().toLowerCase(),
                    firstname: firstName,
                    iataNo: IATANumber,
                    password: password,
                    phonenumber: phoneNumber,
                    surname: lastName,
                    companytype: registrar,
                    title: title,
                    fiataNo: fiata,
                    globalEmailid: globalEmail,
                    agentCode: registrar === CompanyTypes.FORWARDER ? `${IATANumber}${CASSNumber}` : ''
                }
            })
            const parsedMessage = JSON.parse(signUpResponse?.data?.doRegistration)
            if (parsedMessage?.status === 'error') {
              setMsgConfig({
                alertOpen: true,
                message: parsedMessage.message,
                severity: "error",
                autoHideDuration: 5000
              })
            } else {
              setIsVisibleModal(true)
              onClose()
            }
        } catch (error: any) {
            errorCheck(error.message)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Fragment>
            <InfoModal
                open={isVisibleModal}
                header=''
                onClose={() => setIsVisibleModal(false)}
            >
                <Typography sx={{ color: 'rgba(5,21,81,0.9)', fontSize: '18px' }}>{`Thank you for registering with TriCargo. We will review your details and respond within 72 hours.`}</Typography>
            </InfoModal>
            <AuthBaseModal isOpen={isOpen} onClose={onClose} title={type === 'gsa' ? "Administration Registration" : "Forwarder Registration"}>
                <Fragment>
                    <BackButton onClick={onBack}>
                        <ArrowBackIcon />
                    </BackButton>
                    <TextInput value={companyName} onChange={textHandler(setCompanyName)} label="Full company name " setIsDisabled={updateIsDisabled("companyName")} validation="string" />
                    <CountryInput value={country} onChange={event => setCountry(event.target.value)} setIsDisabled={updateIsDisabled("country")} />
                    <Grid container gap={2}>
                        <Grid item xs={5.74}>
                            <NumberInput label="IATA Number" value={IATANumber} onChange={textHandler(setIATANumber)} type="IATA" setIsDisabled={updateIsDisabled("IATANumber")} />
                        </Grid>
                        <Grid item xs={5.74}>
                            <NumberInput label="CASS Number" value={CASSNumber} onChange={textHandler(setCASSNumber)} type="CASS" setIsDisabled={updateIsDisabled("CASSNumber")} />
                        </Grid>
                    </Grid>
                    {type === 'forwarder' && <TextField sx={{ width: '100%' }} variant="outlined" label={'FIATA Membership Number (optional)'} value={fiata} onChange={textHandler(setFiata)}/>}
                    <TextInput value={companyWebsite} onChange={textHandler(setCompanyWebsite)} label="Company website " setIsDisabled={updateIsDisabled("companyWebsite")} validation="string" />
                    <PhoneInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} setIsDisabled={updateIsDisabled("phoneNumber")} />
                    {type === 'gsa' && <AirlinesOnBoard onClick={() => setIsOpenSelectAirline(true)} value={airlinesOnBoard} />}
                    <SubmitButton onClick={submit} text="Submit" isDisabled={calcIsDisabled()} isSubmitting={isSubmitting} />
                </Fragment>
            </AuthBaseModal>
            <AirlinesOnBoardInput isOpen={isOpenSelectAirline} airlines={airlines.data?.getAirlines} onClose={() => setIsOpenSelectAirline(false)} onChage={addAirline} value={airlinesOnBoard} />
        </Fragment>
    )
}