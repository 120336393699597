import { Modal, Typography } from "@mui/material";
import { CloseButton, HeaderStack, ModalAnchor } from "./styled";
import tricargologo from "src/Assets/Images/tricargo.jpg";
import CloseIcon from '@mui/icons-material/Close';

export type AuthBaseModalProps = {
    isOpen: boolean
    onClose(): void
    children: JSX.Element
    title: string
}

export const AuthBaseModal = ({ isOpen, onClose, children, title }: AuthBaseModalProps) => (
    <Modal open={isOpen}>
        <ModalAnchor>
            <CloseButton onClick={onClose}>
                <CloseIcon />
            </CloseButton>

            <HeaderStack>
                <img alt="logo" src={tricargologo} width="150" />
                <Typography variant="h5" textAlign="center" sx={{ marginTop: '10px', borderBottom: '2px solid #d79232' }}>{title}</Typography>
            </HeaderStack>

            {children}
        </ModalAnchor>
    </Modal>
)