import { Box, Modal } from '@mui/material'
import { CompanyTypes } from 'src/Utils/UserPermission';
import { useAuth } from 'src/hooks';
import close from 'src/Assets/Images/Close.png'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 1200,
    width: '100%',
    height: 678,
    bgcolor: 'background.paper',
};

export const IntroOverlay = ({ open, handleClose }) => {
    const auth = useAuth();
    const companyType = auth?.userConfig?.companytype;
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <img src={close} alt="close" style={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                    width: '32px',
                    cursor: 'pointer'
                }} onClick={() => handleClose()} />
                <iframe width="100%" height="100%" src={companyType === CompanyTypes.GSSA ? "https://tricargo-images-bucket.s3.eu-west-1.amazonaws.com/Introduction_videos/tricargo_GSA.mp4" : "https://tricargo-images-bucket.s3.eu-west-1.amazonaws.com/Introduction_videos/Forwarder_Instruction_video.mp4"} title="YouTube video player" allow="accelerometer; autoplay; autostop; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </Box>
        </Modal>
    )
}