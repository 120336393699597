import { useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import Footer from 'src/Components/Footer';
import Header from 'src/Components/Header';
import { GetToolTip } from 'src/Reducers/queries';

const Terms = () => {
    const [getToolTip, toolTipData] = useLazyQuery(GetToolTip);
    return (
        <>
            <Header getToolTip={getToolTip} toolTipData={toolTipData}/>
            <Box sx={{ padding: '80px', lineHeight: '40px', backgroundColor: 'white', marginTop: '65px' }}>
                <Typography variant='h6'>Tricargo.aero Terms and Conditions</Typography> <br />

                <Typography variant='h6'>General</Typography><br />

                <Typography sx={{ fontWeight: 500, lineHeight: '40px' }}>These terms and conditions are here to reflect that Tricargo.aero is a “window” allowing signed up parties to
                    see, choose and book with each other easily and quickly. Maximum choice with minimum fuss is our objective
                    for you all. We do not take part in the commercial transaction we simply facilitate. <u>The current charge for this is
                        only for the GSA/Carrier or other service provider and is chf 0.95 flat fee per air waybill issued on a booking.</u>
                </Typography><br />

                https://tricargo.aero/ (“this Site“) is the official website of FAGSA Commercial Ltd’s tricargo.aero. (“Tricargo“). Your
                access and use of this Site constitute your acceptance and agreement (the “Agreement”) to be bound by these terms
                and conditions of use (“Terms and Conditions”). You represent and warrant you possess the legal right and ability to
                enter into this Agreement and to use this Site in accordance with all Terms and Conditions herein. If you do not consent
                to be bound by these Terms and Conditions, please discontinue your access and do not use this Site.<br /><br />

                In case of any violation of these Terms and Conditions, TRICARGO reserves the right to seek all remedies available
                under the law and in equity for such violations. These Terms and Conditions apply in respect of any and all visits to this
                Site, both now and in the future. <br /><br />

                TRICARGO may, from time to time and at its sole and absolute discretion, amend these Terms and Conditions without
                prior notice. The amended Terms and Conditions will be posted on this Site. Your access and use of this Site after the
                amended Terms and Conditions have been posted, will constitute your acceptance and agreement to be bound by the
                amended Terms and Conditions. For the avoidance of doubt, references to these “Terms and Conditions” shall include
                such Terms and Conditions as amended from time to time by TRICARGO and posted on this Site.<br /><br />

                <Typography variant='h6'>Modification of Terms</Typography><br />

                TRICARGO attempts to ensure that the information contained on this Site is as accurate as possible. However,
                inaccuracies may arise from time to time. TRICARGO accepts no responsibility for any errors in the information
                contained on this Site.<br /><br />

                This Site contains, inter alia, information about the products and services through TRICARGO by a third party is offering.
                This information and all other materials on this Site are provided in good faith. By using this Site, you agree and
                acknowledge that TRICARGO may update, change, or amend the Terms and Conditions at any time without advance
                notice.<br /><br />

                Any updated Terms and Conditions will become effective, once posted on this Site, and will not have any retrospective
                effect on existing contractual arrangements made through this Site. You should re-read these Terms and Conditions
                every time you visit this Site to ensure that you are aware of all the relevant and most current Terms and Conditions.
                Accordingly, your continued access and use of this Site, after any change, is deemed to be your acceptance of the
                modified Terms and Conditions that have been posted, and will constitute your acceptance and agreement to be bound
                by the amended Terms and Conditions. For the avoidance of doubt, references to these “Terms and Conditions” shall
                include such Terms and Conditions as amended from time to time by TRICARGO and posted on this Site.<br /><br />

                <Typography variant='h6'>Conditions of Carriage & Contract</Typography><br />

                You accept that the data (including, but not limited to, displayed rates, transit times, type of services) displayed on this
                Site are for estimations and information only. All engagement of services provided by any GSA, carrier, airline or partner
                agent listed on this Site, including, but not limited to, its executives, officers, employees, agents and representatives who
                are using this Site (“Carrier”) and engaged by freight forwarder, including, but not limited to, its executives, officers,
                employees, agents and representatives who are using this Site (“Freight Forwarder”) (Freight Forwarder and Carrier are
                collectively referred to as “Users”) are subject to the revised rates, standard published terms and conditions, conditions of
                carriage, conditions of contract of the Carrier /GSA concerned, air waybill, general rules tariff and any other conditions
                provided by the Carrier / GSA concerned (collectively referred to as “Final Carrier Agreements”); which shall be
                communicated, finalised and confirmed between the Carrier/GSA and the Freight Forwarder concerned, and to the
                ground charges and associated conditions provided by the Partner Agent concerned, which shall be communicated,
                finalised and confirmed between the Freight Forwarder and the Partner Agent concerned. All Freight Forwarders accept
                that the Final Carrier Agreements and possible Partner Agent Agreement for any import freight services may differ from
                what is originally displayed on this Site.<br /><br />

                <Typography variant='h6'>Waiver of Penalties</Typography><br />

                Whenever a finalised transaction or finalised service to be performed by Carrier are cancelled by the either the Carrier or
                the Freight Forwarder due to whatever reason(s), the cancelling party can apply for waiver of penalty charges by
                presenting written document to TRICARGO stating reason(s) for such cancellation. TRICARGO reserves all rights and
                sole discretions in deciding on whether reason(s) provided are reasonable and acceptable, and whether the applicable
                penalty charges will be waived.<br /><br />

                <Typography variant='h6'>Limitation of Liability and Disclaimer of Warranties</Typography><br />

                The Users are responsible for and warrants their compliance with all applicable laws, rules, and regulations including, but
                not limited to, customs laws, import and export laws and governmental regulation of any country to, from, through or over
                which the shipment may be carried. The Users agree to furnish such information to comply with such laws, rules, and
                regulations. TRICARGO has no liability to the Users for any loss or expense due to the failure of any party not complying
                with this provision. TRICARGO is not liable for any loss, damage, delay, mis-delivery and/or non-delivery caused by:<br />
                <ul>
                    <li>failure by any party to comply with delivery and/or other instructions;</li>
                    <li>the act, negligence, default, or omission of the Carrier and/or the Freight Forwarders or Partner Agent and/or
                        any other party who claims interest in the shipment;</li>
                    <li>the nature of the shipment or any defect thereof;</li>
                    <li>violation(s) of Final Carrier Agreements by the Carrier and/or Freight Forwarders or Partner Agents and/or any
                        other party who claims interest in the shipment including, but not limited to, improper or insufficient packing,
                        securing, marking or addressing, or of failure to observe any of the rules relating to shipments not acceptable
                        for transportation or shipments acceptable only under certain conditions; and</li>
                    <li>the acts of God, perils of the air, public enemies, public authorities, acts or omissions of Customs or quarantine
                        officials, war, riots, strikes, labour disputes, weather conditions or mechanical delay or failure of aircraft or other
                        equipment.</li>
                </ul>

                TRICARGO makes no representations or warranties, either expressed or implied, with respect to this Site or the
                contents and, to the fullest extent permissible under the law, disclaims all such representations and warranties.
                Subject to applicable law, under no circumstance is TRICARGO responsible for any direct or indirect, incidental, special,
                punitive, exemplary or consequential damages of any kind (including, but not limited to, lost profits, lost savings or
                revenue, or loss or corruption of data or information) which arises out of or is in any way connected with your use of or
                inability to use this Site whether based on breach of contract, tort, negligence, product liability or otherwise, even if
                advised of the possibility of such damages.<br /><br />

                Your access to and use of this Site is solely at your own risk. TRICARGO makes no warranty whatsoever about the
                reliability, stability, or virus-free nature of this Site.
                You shall obtain your own professional and/or legal advice and conduct all necessary due diligence, including, but not
                limited to, making such other investigations, or seeking clarifications as may be appropriate, as regards any decision or
                action that you intend to take in relation to any matter concerning all information and materials contained in this Site.<br /><br />

                <Typography variant='h6'>Indemnity</Typography><br />

                You hereby agree to indemnify TRICARGO and hold TRICARGO harmless from and against any and all claims, losses,
                liabilities, costs, and expenses (including, but not limited to, legal costs and expenses on a full indemnity basis) made
                against or suffered or incurred by TRICARGO however arising directly or indirectly from:<br />
                <ul>
                    <li>your access to or use of this Site; or</li>
                    <li>your breach of any of these Terms & Conditions.</li>
                </ul>

                <Typography variant='h6'>Modification of Terms</Typography><br />

                TRICARGO attempts to ensure that the information contained on this Site is as accurate as possible. However,
                inaccuracies may arise from time to time. TRICARGO accepts no responsibility for any errors in the information
                contained on this Site. Services on offer through this site are provided directly by the users and TRICARGO is merely a
                window for conveyance of request and answer.<br /><br />

                This Site contains, inter alia, information about the products and services of TRICARGO’s third party is offerings. This
                information and all other materials on this Site are provided in good faith. By using this Site, you agree and acknowledge
                that TRICARGO may update, change, or amend the Terms and Conditions at any time without advance notice.
                Any updated Terms and Conditions will become effective, once posted on this Site, and will not have any retrospective
                effect on existing contractual arrangements made through this Site. You should re-read these Terms and Conditions
                every time you visit this Site to ensure that you are aware of all the relevant and most current Terms and Conditions.
                Accordingly, your continued access and use of this Site, after any change, is deemed to be your acceptance of the
                modified Terms and Conditions.rms and Conditions have been posted, will constitute your acceptance and agreement
                to be bound by the amended Terms and Conditions. For the avoidance of doubt, references to these “Terms and
                Conditions” shall include such Terms and Conditions as amended from time to time by TRICARGO and posted on this
                Site.<br /><br />

                <Typography variant='h6'>Site Availability & Right of Access</Typography><br />

                While TRICARGO uses all reasonable endeavours to make the Site available, TRICARGO cannot guarantee that they
                will operate continuously or without interruption. TRICARGO may, at its sole and absolute discretion, grant or refuse
                permission to use this Site, and impose such conditions for the use of this Site as it deems fit.<br /><br />

                <Typography variant='h6'>Acceptable Use</Typography><br />

                When using this Site you agree:<br />
                <ul>
                    <li>to use them solely to determine the availability of products and services offered by TRICARGO’s participants;</li>
                    <li>not to deface, alter or interfere with the front end “look and feel” of this Site or the underlying software code;</li>
                    <li>not to take any action which will impose an unreasonable or disproportionately large load on this Site or related
                        infrastructure;</li>
                    <li>to full and timely payment of all amounts due;</li>
                    <li>that all fees, assessments, charges, taxes, and duties arising out of use of this Site are your sole responsibility;</li>
                    <li>refrain from doing anything which will or might damage, interfere with, disrupt access to, interrupt or impair the
                        functionality of the material available on this Site, nor to upload any material that contains any computer viruses
                        or other codes, files or programs designed to interrupt, destroy, or limit the functionality of any software or
                        equipment;</li>
                    <li>refrain from posting, distributing or otherwise uploading any defamatory, libellous, offensive, infringing, obscene,
                        indecent, harmful, threatening, abusive, tortuous, racially, or ethnically objectionable or otherwise unlawful
                        material or information or send any chain letters, junk mail, ‘spamming’ material or any other form of bulk
                        communication;</li>
                    <li>not to threaten, harass, stalk, abuse, disrupt or otherwise violate the rights of others, including individuals’
                        privacy rights;</li>
                    <li>not to obtain or attempt to obtain unauthorized access to this Site or any private or member account areas on
                        this Site, nor to impersonate any person or misrepresent your affiliation with another person;</li>
                    <li> not do, cause, or permit anything to be done that may neither infringe, damage, or interfere with any intellectual
                        property rights of TRICARGO or any of our associated companies or third parties;</li>
                    <li> not to do anything that is otherwise unlawful, or which may create any liability on the part of TRICARGO;</li>
                    <li> violate the Terms and Conditions or any applicable legislation in any other manner.</li>
                </ul>

                If TRICARGO considers that you have breached any of these Terms & Conditions or have otherwise demonstrated
                inappropriate conduct when using this Site, TRICARGO reserves the right to:<br />
                <ul>
                    <li>issue a warning for breaching these Terms and Conditions, and demand you to stop such conduct;</li>
                    <li>delete any material or content which you may have posted without any prior notice;</li>
                    <li>take measures (including terminating, suspending, or restricting your access) to, and preventing you from using
                        this Site;</li>
                    <li>report your activities to an internet service provider and/or relevant authorities; and</li>
                    <li>take legal action against you.</li>
                </ul>

                <Typography variant='h6'>Links to Third-Parties’ Websites</Typography><br />

                This Site may contain links and pointers to internet sites maintained by third parties. TRICARGO does not operate or
                control in any respect any information, products, or services on such third-party sites. Third-party links and pointers are
                included for your convenience and do not constitute any endorsement by us. You assume sole responsibility for use of
                third-party links and pointers.<br /><br />

                <Typography variant='h6'>Privacy Policy</Typography><br />

                We are a neutral independent organisation ultimately controlled on a day to day basis by the secretary general of
                Fedagsa Geneva. Your data safety is one of the primary reasons this product was built. We are obliged to convey the
                following as part of the terms and conditions. Unless expressly agreed otherwise in writing, by visiting our websites and
                related applications, whether on a computer, phone, tablet, or any other electronic device (these are all referred to as
                “Device(s)”), you accept that TRICARGO may collect (via Cookies or other means), hold, store, process (by electronic and non-electronic means),
                utilize and share information about you, your Devices and your use of our Site including, but not limited to, the company
                name, any profile details provided by the Users, user behaviour (such as bidding patterns and preferences) <u>for the
                    purposes of meeting regulatory requirements, meeting tax and audit requirements,</u> We also seek to improve and
                enhance our services and the Site, and conduct market research, marketing, advertising, promotions and/or
                communications. TRICARGO may also share information about you, your Devices, and your use of our Services with
                our data suppliers which you in turn have access to only <span style={{ fontWeight: 500 }}>pursuant to written agreements which include confidentiality,
                    privacy, and security obligations. Your data is not marketed.</span><br /><br />

                <Typography variant='h6'>Copyright and Trademarks</Typography><br />

                All content of this Site is subject to copyright. You may not modify, copy, distribute, transmit, display, perform, reproduce,
                publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained
                from this Site. TRICARGO referenced on this Site are our trademarks. Other third-party product and company names
                mentioned herein may be the trademarks of their respective owners.<br /><br />

                <Typography variant='h6'>Governing Law</Typography><br />

                These Terms and Conditions shall be governed by and construed in accordance with the laws of England & Wales, and
                you agree to submit to the exclusive jurisdiction of the courts of England.

            </Box >
            <Footer />
        </>
    )
}

export default Terms