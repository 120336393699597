import { Box, Typography } from "@mui/material";
import { FooterContainer } from "./styled";
import { Link } from "react-router-dom";
const Footer = () => {

  return (
    <FooterContainer>
      <Box className="footer_wrp">
        <Box className="footer_bottom">
          <Box className="footer_bottom_box">
            <Box className="menu_items">
              <Typography variant="h3">© Copyright 2022 tricargo.aero </Typography>
              <Link to="/terms">Terms, Privacy and Cookie policies</Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </FooterContainer>
  );
};

export default Footer;
