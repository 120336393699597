import { Box, Button, InputLabel, Stack } from "@mui/material"
import styled from "styled-components"

export const InputStack = styled(Stack)`
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    position: relative !important;
`

export const EyeBox = styled(Box)`
    position: absolute;
    right: 10px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    display: flex;
`


export const SubmitButtonStyled = styled(Button)`
    width: 100%;
    border-radius: 25px !important;
    background-color: #ED1C24 !important;
    color: #fff !important;

    &:disabled {
        background-color: #494242 !important;
        cursor: not-allowed !important;
    }
`

export const CheckBoxStack = styled(Stack)`
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100%;
`

export const CheckboxLabel = styled(InputLabel)`
    cursor: pointer !important;
    user-select: none !important;

    @media (hover: hover) {
        &:hover {
            color: #ED1C24 !important;
        }
    }
`

export const LinkButton = styled.a`
    text-decoration: none !important;
    color: #0E6ED6;
    position: relative;
    z-index: 1;

    @media (hover: hover) {
        text-decoration: underline;
    }
`

export const ModalBackground = styled(Box)`
    position: absolute;
    inset: 0 !important;
    margin: auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: fit-content !important;
    width: clamp(300px, 70vw, 500px) !important;
    background-color: #fff !important;
    border-radius: 8px !important;
    padding: 50px 60px !important;
    padding-top: 50px !important;
`

export const AirlineButtonStyled = styled(Button)`
    position: relative;
    width: 100%;
    border-radius: 6px !important;
    border: 1px solid #c5c4c4 !important;
    box-sizing: border-box !important;
    padding: 16.5px 14px !important;    
    text-align: start !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1rem;
`