import { Box, Typography } from '@mui/material';
import { InfoModalContainer, InfoStyledModal } from 'src/Components/WarnModel/style';
import { InfoModalProps } from './types'
import Close from 'src/Assets/Images/Close.png'

const WarnModel = (props: InfoModalProps) => {
  const {
    open,
    body,
    onClose,
    header,
    children,
    className = "confirm_modal",
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Box >
      <InfoStyledModal
        sx={{ overflow: 'auto', display: { sm: 'block', md: 'none' } }}
        open={open}
        onClose={handleClose}
      >
        <InfoModalContainer>
          <Box style={{ "maxWidth": 500, 'border': '3px solid black', borderRadius: '20px' }} className={`warn_modal_mobile`} >
            <Box className="modalHeader">
              <Typography
                className="headerText">
                {header}
              </Typography>

              <Box sx={{ cursor: 'pointer', display: 'flex' }} onClick={handleClose}>
                <img className="closeIcon" src={Close} alt="Close" />
                <Typography className="closeText">close</Typography>
              </Box>
            </Box>
            {body && (
              <Box className="modalBody">
                <Typography color='text.secondary'>{body}</Typography>
              </Box>
            )}
            {children && (
              <Box className='modalBody' >
                {children}
              </Box>
            )}
            <Box className="modalFooter">
            </Box>
          </Box>
        </InfoModalContainer>
      </InfoStyledModal>
    </Box >
  );
}
export default WarnModel;
